import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  placeTri,
  shuffle,
  placeEraseLeft,
  placeEraseRight
} from '../../../../common/edliy_utils-fractions';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-9, 19, 15, -5],
        keepaspectratio: true, axis:false, ticks:{visible:true},
        grid:true, showCopyright:false, showNavigation:false,
        pan:{enabled:false}, zoom:{enabled:false}});

    brd1.suspendUpdate();
    // Layering
    brd1.options.axis.strokeWidth=0;
    brd1.options.layer['image']=15;
    brd1.options.layer['text']=16;
    brd1.options.layer['line']=16;
    brd1.options.layer['point']=17;
    brd1.options.layer['glider']=17;
    brd1.options.layer['angle']=18;
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    brd1.options.line.highlight=false;
    brd1.options.image.highlight=false;
    brd1.options.text.highlight=false;
    brd1.options.layer['image'] =2;
    brd1.options.layer['point'] =3;
//    brd1.options.text.display='internal';
    //Make the board responsive
    makeResponsive(brd1);
    // Creat titleStyle
    placeLogo(brd1);
    placeTitle(brd1, 'Three D Shapes', '(Draw the Front, Top & Side Views of the 3D Object Shown)');
/////////////////////
    var analytics = placeEraseLeft(brd1);
    analytics.setLabel('Tap to Erase & Start Over')
    analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    analytics.on('over', function () {this.label.setAttribute({visible:true});});
    analytics.on('out', function () {this.label.setAttribute({visible:false});});
    ////////////////////////////////////////////////////
    var erase = placeTest(brd1);
    erase.setLabel('Tap to Test Your Answer')
    erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
    erase.on('over', function () {this.label.setAttribute({visible:true});});
    erase.on('out', function () {this.label.setAttribute({visible:false});});
/////////////////////////////////////////////////////////////////////////////
    var rght = placeImage(brd1, '/assets/check.svg', -4, -1., 1.,  0);
    var rght2 = placeImage(brd1, '/assets/check.svg', 5, -1., 1.,  0);
    var wrng = placeImage(brd1, '/assets/cross.svg', -4, -1., 1.,  0);
    var wrng2 = placeImage(brd1, '/assets/cross.svg', 5, -1., 1.,  0);
    var rght3 = placeImage(brd1, '/assets/check.svg', 13, -1., 1.,  0);
    var wrng3 = placeImage(brd1, '/assets/cross.svg', 13, -1., 1.,  0);
    rght.setAttribute({visible:false});
    rght2.setAttribute({visible:false});
    rght3.setAttribute({visible:false});
    wrng.setAttribute({visible:false});
    wrng2.setAttribute({visible:false});
    wrng3.setAttribute({visible:false});
  //  placeMiddleText(brd1, 0, 12, ()=>'Given Number =' + NumList[i]);
    /////////////////////////////////////////////////////////
    //3D Shape
    var x1 = -2;
    var y1 = 8;
    var shape=[];
    var front=[];
    var side =[];
    var top = [];
    ////////////////////////////////////////////////////////////////////////////
    function checkColorFront(){

        if(colors[0] == 'red' && colors[1] == 'red' && colors[2] == 'red' && colors[3] == 'white' && colors[4] == 'white'&& colors[5] == 'red' && colors[6] == 'white'){
            rght.setAttribute({visible: true});
            wrng.setAttribute({visible:false});
        }
        else{
            wrng.setAttribute({visible:true});
            rght.setAttribute({visible:false});
        }
    }
    function checkColorTop(){

        if(blues[0] == 'blue' && blues[1] == 'blue' && blues[2] == 'blue' && blues[3] == 'white' && blues[4] == 'white' && blues[5] == 'blue' && blues[6] == 'white'){
            rght2.setAttribute({visible: true});
            wrng2.setAttribute({visible:false});
        }
        else{
            wrng2.setAttribute({visible:true});
            rght2.setAttribute({visible:false});
        }
    }
    function checkColorSide(){

        if(greens[0] == 'green' && greens[1] == 'green' && greens[2] == 'green' && greens[3] == 'green'){
            rght3.setAttribute({visible: true});
            wrng3.setAttribute({visible:false});
        }
        else{
            wrng3.setAttribute({visible:true});
            rght3.setAttribute({visible:false});
        }
    }
    erase.on('down', function(){checkColorFront();checkColorTop(); checkColorSide();})
    var colors=[]; var blues=[]; var greens=[];
    colors[0]='white';
    colors[1]='white';
    colors[2]='white';
    colors[3]='white';
    colors[4]='white';
    colors[5]='white';
    colors[6]='white';
  ////////////////////////////////////
    blues[0]='white';
    blues[1]='white';
    blues[2]='white';
    blues[3]='white';
    blues[4]='white';
    blues[5]='white';
    blues[6]='white';
    ////////////////////////////////////////////////////////
    greens[0]='white';
    greens[1]='white';
    greens[2]='white';
    greens[3]='white';
    greens[4]='white';
    greens[5]='white';
    greens[6]='white';
///////////////////////////////////////////
    front[0]= brd1.create('regularpolygon', [[x1, y1], [x1+2, y1], 4], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    front[1] = brd1.create('regularpolygon', [[x1+2, y1], [x1+4, y1], 4], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    front[2] = brd1.create('regularpolygon', [[x1+4, y1], [x1+6, y1], 4], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    front[3] = brd1.create('regularpolygon', [[x1+4, y1], [x1+6, y1], 4], {visible:false, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    front[4] = brd1.create('regularpolygon', [[x1+4, y1], [x1+6, y1], 4], {visible:false, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    front[5] = brd1.create('regularpolygon', [[x1+4, y1+2 ], [x1+6, y1+2], 4], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    front[6] = brd1.create('regularpolygon', [[x1+4, y1], [x1+6, y1], 4], {visible:false, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
//////////////////////////////////////////////////////////////////////
    side[0] = brd1.create('polygon', [[x1+6, y1], [x1+7.25, y1+1], [x1+7.25, y1+3], [x1+6, y1+2] ], {visible:true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    side[2] = brd1.create('polygon', [[x1+6, y1+2], [x1+7.25, y1+3], [x1+7.25, y1+5], [x1+6, y1+4] ], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    side[1] = brd1.create('polygon', [[x1+7.25, y1+1], [x1+8.5, y1+2], [x1+8.5, y1+4], [x1+7.25, y1+3]], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    side[3] = brd1.create('polygon', [[x1+7.25, y1+3], [x1+8.5, y1+4], [x1+8.5, y1+6], [x1+7.25, y1+5]], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});

///////////////////////////////////////////////////////////////////////////
    top[2] = brd1.create('polygon', [[x1+6, y1+4], [x1+7.25, y1+5], [x1+5.25, y1+5], [x1+4, y1+4] ], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    top[0] = brd1.create('polygon', [[x1, y1+2], [x1+2, y1+2], [x1+3.25, y1+3], [x1+1.25, y1+3]], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    top[1] = brd1.create('polygon', [[x1 + 2, y1+ 2], [x1 + 4, y1 + 2], [x1+4, y1+3], [x1+3.25, y1+3]], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    top[5] = brd1.create('polygon', [[x1+5.25, y1+5], [x1+7.25, y1+5], [x1+8.5, y1+6], [x1+6.5, y1+6]], {visible:true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    top[3] = brd1.create('polygon', [[x1+5.25, y1+5], [x1+7.25, y1+5], [x1+8.5, y1+6], [x1+6.5, y1+6]], {visible:false, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    top[4] = brd1.create('polygon', [[x1+5.25, y1+5], [x1+7.25, y1+5], [x1+8.5, y1+6], [x1+6.5, y1+6]], {visible:false, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    top[6] = brd1.create('polygon', [[x1+5.25, y1+5], [x1+7.25, y1+5], [x1+8.5, y1+6], [x1+6.5, y1+6]], {visible:false, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
    //////////////////////////////////////////////////////////////////////////////////////////////
    //var rectangle15 = brd1.create('polygon', [[x1+3.5, y1+3], [x1+4, y1+3.4]], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 2}, fillColor: 'white', fillOpacity:1.0});
  //FRONT
    var width=2;
    var num_height=2;
    var num_width=3;
    var rects=[];
    var rects2=[];
    var rectsVisibility=[];
    var rectsVisibility2=[];
    for (let x=0; x<3; x++)
    {
        let rectx=[];
        let rectx2=[];
        let vx=[];
        let vx2=[];

        for (let y=0; y< num_height; y++)
        {
            let v = true;
            let v2 = false;
            var rect = brd1.create('image', ['/assets/whiteSquare.svg', [x*width-1.5*num_width*width+1, y*width-0.0*num_width*width+1], [2.0,2.0]], {visible: v, opacity:0.25, fixed:true});
            var rect2 = brd1.create('image', ['/assets/purpleSquare.svg', [x*width-1.5*num_width*width+1, y*width-0.0*num_width*width+1], [2.0,2.0]], {visible: v2, fixed:true});
            rect.on('up', function(){changeRectColorxy(x,y);  colors[x+3*y]='red'; front[x+3*y].setAttribute({color:'red'})});
            rect2.on('up', function(){changeRectColorxy(x,y); colors[x+3*y]='white'; front[x+3*y].setAttribute({color:'white'})});
            rectx.push(rect);
            rectx2.push(rect2);
            vx.push(v);
            vx2.push(v2);
        }
        rects.push(rectx);
        rects2.push(rectx2);
        rectsVisibility.push(vx);
        rectsVisibility2.push(vx2);
    }
// Top
var width=2;
var Trects=[];
var Trects2=[];
var TrectsVisibility=[];
var TrectsVisibility2=[];
for (let x=0; x<3; x++)
{
    let rectx=[];
    let rectx2=[];
    let vx=[];
    let vx2=[];

    for (let y=0; y<2; y++)
    {
        let v = true;
        let v2 = false;
        var Trect = brd1.create('image', ['/assets/whiteSquare.svg', [x*width-0.*num_width*width+1, y*width-0.0*num_width*width+1], [2.0,2.0]], {visible: v, opacity:0.25, fixed:true});
        var Trect2 = brd1.create('image', ['/assets/blueSquare.svg', [x*width-0.*num_width*width+1, y*width-0.0*num_width*width+1], [2.0,2.0]], {visible: v2, fixed:true});
        Trect.on('up', function(){changeTrectColorxy(x,y);  blues[x+3*y]='blue'; top[x+3*y].setAttribute({color:'blue'})});
        Trect2.on('up', function(){changeTrectColorxy(x,y); blues[x+3*y]='white'; top[x+3*y].setAttribute({color:'white'})});
        rectx.push(Trect);
        rectx2.push(Trect2);
        vx.push(v);
        vx2.push(v2);
    }
    Trects.push(rectx);
    Trects2.push(rectx2);
    TrectsVisibility.push(vx);
    TrectsVisibility2.push(vx2);
}
// SIDE
var width=2;
var Lrects=[];
var Lrects2=[];
var LrectsVisibility=[];
var LrectsVisibility2=[];
for (let x=0; x<2; x++)
{
    let rectx=[];
    let rectx2=[];
    let v = true;
    let v2 = false;
    let vx=[];
    let vx2=[];
    for (let y=0; y<2; y++)
    {
        var Lrect = brd1.create('image', ['/assets/whiteSquare.svg', [x*width+1.5*num_width*width+1, y*width-0.0*num_width*width+1], [2.0,2.0]], {visible:true, opacity:0.25, fixed:true});
        var Lrect2 = brd1.create('image', ['/assets/greenSquare.svg', [x*width+1.5*num_width*width+1, y*width-0.0*num_width*width+1], [2.0,2.0]], {visible:false, fixed:true});
        Lrect.on('up', function(){changeLrectColorxy(x,y);  greens[x+2*y]='green'; side[x+2*y].setAttribute({color:'green'})});
        Lrect2.on('up', function(){changeLrectColorxy(x,y); greens[x+2*y]='white'; side[x+2*y].setAttribute({color:'white'})});
        rectx.push(Lrect);
        rectx2.push(Lrect2);
        vx.push(v);
        vx2.push(v2);
    }
    Lrects.push(rectx);
    Lrects2.push(rectx2);
    LrectsVisibility.push(vx);
    LrectsVisibility2.push(vx2);
}
placeMiddleText(brd1, -5., -0.5, 'Front');
placeMiddleText(brd1,  4., -0.5, 'Top');
placeMiddleText(brd1, 12., -0.5, 'Side');
////////////////////////////////////////////////////////////////////////////////////
function changeRectColorxy(x,y){
    let current_visibility = rectsVisibility[x][y];
    let current_visibility2 = rectsVisibility2[x][y];
    if (current_visibility==true && current_visibility2==false)
        {
            current_visibility=false;
            current_visibility2=true;

        }
    else{
            current_visibility=true;
            current_visibility2=false;
        }

    rects[x][y].setAttribute({visible: current_visibility});
    rects2[x][y].setAttribute({visible: current_visibility2});
    rectsVisibility[x][y]=current_visibility;
    rectsVisibility2[x][y]=current_visibility2;
    }
//////////////////////////////////////////////////////////////////
function changeTrectColorxy(x,y){
    let current_visibility = TrectsVisibility[x][y];
    let current_visibility2 = TrectsVisibility2[x][y];
    if (current_visibility==true && current_visibility2==false)
        {
            current_visibility=false;
            current_visibility2=true;

        }
    else{
            current_visibility=true;
            current_visibility2=false;
        }

    Trects[x][y].setAttribute({visible: current_visibility});
    Trects2[x][y].setAttribute({visible: current_visibility2});
    TrectsVisibility[x][y]=current_visibility;
    TrectsVisibility2[x][y]=current_visibility2;
    }
////////////////////////////////////////
function changeLrectColorxy(x,y){
    let current_visibility = LrectsVisibility[x][y];
    let current_visibility2 = LrectsVisibility2[x][y];
    if (current_visibility==true && current_visibility2==false)
        {
            current_visibility=false;
            current_visibility2=true;

        }
    else{
            current_visibility=true;
            current_visibility2=false;
        }

    Lrects[x][y].setAttribute({visible: current_visibility});
    Lrects2[x][y].setAttribute({visible: current_visibility2});
    LrectsVisibility[x][y]=current_visibility;
    LrectsVisibility2[x][y]=current_visibility2;
    }
//////////////////////////////////////////////////////////////////////
    function clearVisibility(){
      rght.setAttribute({visible:false});
      wrng.setAttribute({visible:false});
      rght2.setAttribute({visible:false});
      wrng2.setAttribute({visible:false});
      rght3.setAttribute({visible:false});
      wrng3.setAttribute({visible:false});
      for (let x=0;x<num_width;x++)
      {
           for (let y=0;y<num_height;y++)
           {
             rects[x][y].setAttribute({visible:true});
             rects2[x][y].setAttribute({visible:false});
             rectsVisibility[x][y]=true;
             rectsVisibility2[x][y]=false;
             Trects[x][y].setAttribute({visible:true});
             Trects2[x][y].setAttribute({visible:false});
             TrectsVisibility[x][y]=true;
             TrectsVisibility2[x][y]=false;
             front[x+3*y].setAttribute({color:'white'});
             top[x+3*y].setAttribute({color:'white'});
             colors[x+3*y] = 'white';
             blues[x+3*y] ='white';
           }
      }
      for (let x=0;x<2;x++)
      {
           for (let y=0;y<2;y++)
           {
             Lrects[x][y].setAttribute({visible:true});
             Lrects2[x][y].setAttribute({visible:false});
             LrectsVisibility[x][y]=true;
             LrectsVisibility2[x][y]=false;
             side[x+2*y].setAttribute({color:'white'});
             greens[x+2*y] = 'white';
           }
      }

                          }

    analytics.on('down', function(){
      clearVisibility();
    });
///////////////////////////////////////////////////////////////////////
    brd1.unsuspendUpdate();
                      },
              }
export default Boxes;
